"use client";
import { Roboto } from "next/font/google";
import Head from "next/head";
import React from "react";

const roboto = Roboto({
  weight: ["400", "700"],
  style: ["normal"],
  subsets: ["latin"]
});

export const ThemeLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <html lang="en">
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <body className="bg-base-100">
        <main className={roboto.className}>
          <div className="flex flex-col md:flex-row justify-center min-h-screen">{children}</div>
          <div id="react-portal" className="fixed w-full top-0 z-[9999]"></div>
        </main>
      </body>
    </html>
  );
};
